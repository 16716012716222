<script>
import LoadSections from '@/mixins/load-sections';
import View from '@/views/View';

export default {
  name: 'KDAProcess',
  metaInfo: { title: 'Process' },
  extends: View,

  mixins: [LoadSections(['KDAProcessContainer'])],

  props: {
    id: {
      type: String,
      default: 'process'
    }
  },

  mounted() {
    window.gtag('event', 'page_view', {
      'page_title': 'Process'
    })
  }, 
};
</script>
